import MainTabButton from '@/components/MainTabButton';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface HospitalProps {
  hospitals: HospitalData[];
}

export interface HospitalData {
  id: string | undefined;
  letterGroup: any;
  hospitalName: string | undefined;
}

const PanelContainer = styled.div`
  overflow-y: auto;
  .UiTabPanel {
    display: inline-flex;
    margin: 0;
    & > li {
      margin-right: 8px;
      padding: 0;

      button {
        height: 56px;
        line-height: 54px;
        width: auto;
      }
    }
    ${breakpoint(`tablet`)} {
      padding-bottom: 40px;
    }
    ${breakpoint(640)} {
      overflow-x: scroll;
      white-space: nowrap;
      display: block;
      & > li {
        display: inline-block;
        button {
          width: 48px;
          height: 48px;
          padding: 0;
          line-height: 48px;
        }
      }
    }
  }
`;

const HospitalFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  & > li {
    padding: 7px 16px;
    width: 25%;
    line-height: 1.2;
    white-space: normal;
    word-break: normal;
  }

  ${breakpoint(`tablet`)} {
    & > li {
      width: 50%;
    }
  }
`;

const Hospital: FC<HospitalProps> = ({ hospitals }) => {
  const [activeTab, setActiveTab] = useState(`ㄱ`);

  const handleTabMenu = (id: string) => {
    setActiveTab(id);
  };

  const letterItem: string[] = [];
  hospitals.map((item) => letterItem.push(item.letterGroup));
  const letterFilter = letterItem.filter(
    (item, index) => letterItem.indexOf(item) === index,
  );

  const hospitalList = hospitals.filter((code) =>
    code.letterGroup.includes(String(activeTab)),
  );

  return (
    <>
      <PanelContainer>
        <ul className="UiTabPanel flex">
          {letterFilter.map((item) => (
            <li key={item}>
              <MainTabButton
                active={activeTab === item}
                onClick={() => handleTabMenu(item)}
              >
                {item}
              </MainTabButton>
            </li>
          ))}
        </ul>
      </PanelContainer>
      <div className="TabContents">
        <HospitalFlex>
          {hospitalList.map((hostpital) => (
            <li key={hostpital.id}>{hostpital.hospitalName}</li>
          ))}
        </HospitalFlex>
      </div>
    </>
  );
};
export default Hospital;
