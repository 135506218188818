import { HospitalControllerService } from '@/__generated__/FrontApi';
import Card1 from '@/assets/img/corporate-and-organization/hospital_support_card_img1@2x.jpg';
import Card2 from '@/assets/img/corporate-and-organization/hospital_support_card_img2@2x.jpg';
import Card3 from '@/assets/img/corporate-and-organization/hospital_support_card_img3@2x.jpg';
import Profile1 from '@/assets/img/corporate-and-organization/hospital_support_profile1@2x.png';
import Profile2 from '@/assets/img/corporate-and-organization/hospital_support_profile2@2x.png';
import Profile3 from '@/assets/img/corporate-and-organization/hospital_support_profile3@2x.png';
import TobBg from '@/assets/img/corporate-and-organization/hospital_support_top_bg@2x.png';
import TopImg from '@/assets/img/corporate-and-organization/hospital_support_top_img@2x.png';
import TopImgM from '@/assets/img/corporate-and-organization/hospital_support_top_imgM@2x.png';
import Button from '@/components/Button';
import { CampaignData } from '@/components/Card/CampaignCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import { TabData } from '@/components/LegacyTab';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import Youtube from '@/components/Youtube';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';
import {
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
  SUPPORT_CATEGORY_ONCE_WORLD,
  SUPPORT_CATEGORY_REGULAR_WORLD,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { SupportStep1Data } from '@/page-blocks/donate/DonateStep1';
import Hospital, {
  HospitalData,
} from '@/page-blocks/involve/corporate-and-organization/hospital';
import { navigate, PageProps } from 'gatsby';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { GtmButtonDonate } from '@/components/Button.gtm';

const items: CampaignData[] = [
  {
    id: 1,
    dec: `"한의원에서 환자를 진료하는 것도 보람있지만, 더 많은 어린이에게 행복을 주고자 유니세프에 후원하게 되었습니다." <small>하늘마음 한의원 일산점 - 정혜미 원장님<small>`,
    pcImage: Profile1,
    mobileImage: Profile1,
  },
  {
    id: 2,
    dec: `"전 세계 어린이가 아프지 않고 건강할 수 있도록 다같이 유니세프 활동에 동참해 주셨으면 좋겠습니다. 여러분도 유니세프와 함께하세요!" <small>밝은눈안과 - 천현철 대표원장님<small>`,
    pcImage: Profile2,
    mobileImage: Profile2,
  },
  {
    id: 3,
    dec: `"도움을 주려 시작했지만, 결국 저희가 더 큰 행복을 얻은 것 같습니다. 고귀한 생명을 지키는 일에 함께하는 것, 이보다 더 큰 기쁨이 있을까요!" <small>에덴메디여성병원 - 신승령 원장님</small>`,
    pcImage: Profile3,
    mobileImage: Profile3,
  },
];

const tab: TabData[] = [
  {
    id: 0,
    youtubeId: `WDGWjgDN1I8`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `특별후원팀`,
    tel: `02-721-5151`,
    email: `major@unicef.or.kr`,
  },
];

const ProfileLayout = styled.div`
  .img-wrap {
    text-align: center;
    ${Image} {
      width: 100%;
      max-width: 264px;
      display: block;
      margin: 0 auto;
    }
  }
  .dec-wrap {
    margin-top: 32px;
    text-align: center;
    ${Tit} {
      line-height: 1.6;
      small {
        font-size: 16px;
        display: block;
        margin-top: 16px;
        font-weight: 400;
      }
    }
  }
`;

const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
  ${breakpoint(`mobile`)} {
    overflow: hidden;
  }
`;

const SectionTop = styled(Section)`
  padding-bottom: 300px !important;

  ${SectionHeader} {
    .head-dec {
      margin-top: 24px;
      line-height: 2;
    }
    padding-bottom: 90px;
  }
  .flex-wrap {
    position: relative;
    padding-right: 57%;

    &::before {
      content: '';
      display: block;
      width: 1266px;
      height: 650px;
      background: url(${TobBg}) center no-repeat;
      background-size: cover;
      position: absolute;
      top: -80px;
      right: 154px;
    }

    .col-left {
      width: 100%;
      position: relative;
      z-index: 1;
      ${Tit} {
        line-height: 1.5;
        strong {
          color: #1cabe2;
        }
      }
      .dec {
        margin-top: 24px;
        max-width: 286px;
      }
    }
    .col-right {
      width: 57%;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translate(0, -50%);

      &::before {
        content: '';
        display: block;
        width: 458px;
        height: 458px;
        background: #e2f0f6;
        border-radius: 100%;
        z-index: -1;
        position: absolute;
        right: -83px;
        bottom: -108px;
      }
    }
  }

  ${breakpoint(1160)} {
    padding-bottom: 25.86vw !important;

    .flex-wrap {
      &::before {
        content: '';
        display: block;
        width: 109.14vw;
        height: 56.03vw;
        top: -6.9vw;
        right: 13.28vw;
      }

      .col-right {
        &::before {
          width: 39.48vw;
          height: 39.48vw;
          right: -7.16vw;
          bottom: -9.31vw;
        }
      }
    }
  }

  ${breakpoint(`mobile`)} {
    .flex-wrap {
      flex-wrap: wrap;
      padding-right: 0;

      &:before {
        width: auto;
        padding-top: 93.33%;
        height: 0;
        left: -20px;
        right: -20px;
        top: -60px;
      }
      .col-right {
        width: 100%;
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0);

        &::before {
          display: none;
        }
      }
    }
  }
`;

const Card = styled.div`
  height: 100%;
  border: 1px solid #e5e6e8;
  padding: 24px;
  padding-bottom: 32px;
  text-align: center;

  img {
    width: 100%;
  }

  ${Tit} {
    margin-top: 32px;
    min-height: 114px;
    line-height: 1.6;
    display: block;

    strong {
      color: #1cabe2;
    }
  }
  ${breakpoint(1160)} {
    padding: 2.07vw;
    padding-bottom: 2.76vw;

    ${Tit} {
      min-height: 9.83vw;
    }
  }

  ${breakpoint(`tablet`)} {
    padding: 16px;

    ${Tit} {
      min-height: 80px;
    }

    button {
      max-width: 148px;
      margin: 0 auto;
    }
  }
`;
const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -16px;

  & > li {
    width: 33.33%;
    padding: 0 16px;
  }

  ${breakpoint(`tablet`)} {
    & > li {
      width: 100%;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
const ProfileContainer = styled.div`
  padding-top: 240px;

  ${Container} {
    position: relative;
    padding-bottom: 64px;

    .swiper-button-disabled {
      display: none;
    }
    .swiper-scrollbar,
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      width: 960px;
      background: #e2f0f6;
      position: absolute;
      top: 88px;
      bottom: 0;
      right: 50%;
      z-index: -1;
    }

    .swiper-container {
      padding-bottom: 0;

      .swiper-slide {
        width: 33.33%;
        padding: 0 32px;
        margin: 0;
      }
    }

    .lit {
      width: 320px;
      margin-right: 53px;
      text-align: center;

      img {
        max-width: 264px;
        margin: 0 auto;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 160px;

    ${Container} {
      &::before {
        width: 100%;
        top: 56px;
        right: 0;
      }

      .swiper-container {
        .swiper-slide {
          width: 320px;
        }
      }

      .swiper-scrollbar {
        display: block;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    ${Container} {
      padding-left: 0;
      padding-right: 0;
      .swiper-container {
        .swiper-slide {
          width: 260px;
          padding: 0 16px;
          // width: 100%;
        }
      }
    }
  }
`;
const SectionHow = styled(Section)`
  h3 {
    padding-bottom: 72px;
  }

  .youtube-tab {
    padding-top: 250px;
  }

  .btn-flex {
    margin: 0 -16px;
    margin-top: 64px;
    flex-wrap: wrap;
    justify-content: space-between;

    & > li {
      width: 49%;
      height: 86px;
      line-height: 84px;
      font-size: 24px;
      border-radius: 86px;
      background: #f8f9fa;
      font-weight: bold;
      text-align: center;
    }
  }

  ${breakpoint(`tablet`)} {
    .youtube-tab {
      padding-top: 120px;
    }

    .btn-flex {
      padding: 0 20px;
      & > li {
        width: 100%;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn {
      height: 60px;
      line-height: 58px;
      font-size: 18px;
      border-radius: 60px;
    }
  }
  ${breakpoint(640)} {
    .btn-flex {
      & > li {
        font-size: 18px;
        padding: 17px 0;
        height: auto;
        line-height: normal;
      }
    }
  }
`;

const SectionDoc = styled(Section)`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      padding-right: 20px;
    }
    button {
      width: 352px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ${breakpoint(`tablet`)} {
      flex-wrap: wrap;

      h2,
      button {
        width: 100%;
      }
      button {
        margin-top: 72px;
      }
    }
  }
`;
const SectionWidth = styled(Section)`
  .TabContents {
    padding-top: 32px;

    ${breakpoint(`tablet`)} {
      padding-top: 50px;
    }
  }
`;

const SectionContact = styled(Section)``;
const HospitalSupport: React.FC<
  PageProps<GatsbyTypes.HospitalSupportPageQuery>
> = ({ location, data }) => {
  const [hospitalLists, setHospitalLists] = useState<HospitalData[]>([]);

  const loadHospitalLists = useCallback(async () => {
    try {
      const {
        data: hospitalSupport,
      } = await HospitalControllerService.getHospitalListUsingGet();
      const uniq = _.uniqBy(hospitalSupport, 'hospitalName');
      setHospitalLists(
        uniq.map((hospital: any) => ({
          id: hospital.hospitalName,
          letterGroup: hospital.letterGroup,
          hospitalName: hospital.hospitalName,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadHospitalLists();
  }, [loadHospitalLists]);

  const supporterType = `group`; // 후원자 유형 (단체)
  const memberTypeCode = `HO`; // 단체 유형 (병원)
  const getSupportPageUrl = (supportTerm: string, supportCategory: string) => {
    return `/donate/?&supportTerm=${supportTerm}&supportCategory=${supportCategory}&supporterType=${supporterType}&memberTypeCode=${memberTypeCode}`;
  };

  return (
    <LayoutWithTitle
      location={location}
      title="병원후원"
      description="for every child, partnerships"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">병원후원이란?</PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프가 안정적이고 효과적으로 어린이들을 보호하고 생명을
                살리는 사업을 할 수 있도록 <br className="m-hide" />
                개인의 이름이 아닌 병원 사업자 명의로 후원하는 프로그램입니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <div className="flex-wrap flex">
            <div className="col-left">
              <h3>
                <Tit size="s2">
                  모든 어린이가 <br />
                  <strong>건강한 세상</strong>을 위해
                </Tit>
              </h3>
              <p className="dec">
                어린이가 위기에 처할 때마다 전문 의료진과 가장 앞장서서 지구촌
                구석구석 어린이의 생명을 살리고, 질병으로부터 어린이를 보호 하고
                있습니다.
              </p>
            </div>
            <div className="col-right">
              <Image pcSrc={TopImg} mobileSrc={TopImgM} />
            </div>
          </div>
        </Container>
      </SectionTop>

      <SectionHow className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                다양한 방법으로 <br />
                함께할 수 있습니다
              </Tit>
            </h2>
          </SectionHeader>
          <h3>
            <Tit size="s1-5" color="sky" weight="normal">
              병원 임직원
            </Tit>
          </h3>

          <CardFlex>
            <li>
              <Card>
                <Image pcSrc={Card1} mobileSrc={Card1} />
                <Tit size="s3">
                  매월 일정 금액으로 <strong>정기후원</strong>
                </Tit>
                <GtmButtonDonate
                  type="button"
                  text="후원하기"
                  title="병원후원-매월 일정 금액으로 정기후원"
                  color="yellow"
                  full
                  goto={getSupportPageUrl(
                    SUPPORT_TERM_REGULAR,
                    SUPPORT_CATEGORY_REGULAR_WORLD,
                  )}
                  gtmInfo={{
                    ...SelectItemDefaultData,
                    itemId: SUPPORT_CATEGORY_REGULAR_WORLD,
                    itemName: '세계 어린이 돕기',
                    itemCategory: SUPPORT_TERM_REGULAR,
                  }}
                  onClickPreEvent={() => {
                    // TODO onClick before call gtm
                    // alert('click');
                  }}
                />
              </Card>
            </li>
            <li>
              <Card>
                <Image pcSrc={Card2} mobileSrc={Card2} />
                <Tit size="s3">
                  진료비의 일정 비율 <br />
                  또는 환자 1명 당 <strong>일정액 기부</strong>
                </Tit>
                <GtmButtonDonate
                  type="button"
                  text="후원하기"
                  title="병원후원-진료비의 일정 비율 또는 환자 1명 당 일정액 기부"
                  color="yellow"
                  full
                  goto={getSupportPageUrl(
                    SUPPORT_TERM_ONCE,
                    SUPPORT_CATEGORY_ONCE_WORLD,
                  )}
                  gtmInfo={{
                    ...SelectItemDefaultData,
                    itemId: SUPPORT_CATEGORY_ONCE_WORLD,
                    itemName: '세계 어린이 돕기',
                    itemCategory: SUPPORT_TERM_ONCE,
                  }}
                  onClickPreEvent={() => {
                    // TODO onClick before call gtm
                    // alert('click');
                  }}
                />
              </Card>
            </li>
            <li>
              <Card>
                <Image pcSrc={Card3} mobileSrc={Card3} />
                <Tit size="s3">
                  <strong>병원 임직원 급여</strong>의 일부 기부
                </Tit>
                <GtmButtonDonate
                  type="button"
                  text="후원하기"
                  title="병원후원-병원 임직원 급여의 일부 기부"
                  color="yellow"
                  full
                  goto={getSupportPageUrl(
                    SUPPORT_TERM_ONCE,
                    SUPPORT_CATEGORY_ONCE_WORLD,
                  )}
                  gtmInfo={{
                    ...SelectItemDefaultData,
                    itemId: SUPPORT_CATEGORY_ONCE_WORLD,
                    itemName: '세계 어린이 돕기',
                    itemCategory: SUPPORT_TERM_ONCE,
                  }}
                  onClickPreEvent={() => {
                    // TODO onClick before call gtm
                    // alert('click');
                  }}
                />
              </Card>
            </li>
          </CardFlex>
        </Container>
        <ProfileContainer>
          <Container>
            <SwiperCustom>
              <Swiper
                slidesPerView="auto"
                navigation
                scrollbar={{ draggable: true }}
              >
                {items.map((row, index) => (
                  <SwiperSlide key={index}>
                    <ProfileLayout className="CardLayout">
                      <div className="img-wrap">
                        <Image
                          className="card-img"
                          pcSrc={row.pcImage}
                          mobileSrc={row.mobileImage}
                        />
                      </div>
                      <div className="dec-wrap">
                        <Tit
                          size="s3"
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: row.dec }}
                        />
                      </div>
                    </ProfileLayout>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperCustom>
          </Container>
        </ProfileContainer>
      </SectionHow>

      <SectionDoc className="by-sub-main-layout">
        <Container>
          <header>
            <h2>
              <Tit size="s1">병원후원 안내자료</Tit>
            </h2>
            <Button
              outline
              ico="down"
              className="button"
              onClick={() =>
                window.open(
                  `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=hospital-donation.pdf&svrFileNm=hospital-donation.pdf`,
                )
              }
            >
              <span className="ico">다운로드</span>
            </Button>
          </header>
        </Container>
      </SectionDoc>

      <SectionWidth className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">함께하는 병원</Tit>
            </h2>
          </SectionHeader>
          <Hospital hospitals={hospitalLists} />
        </Container>
      </SectionWidth>
      <SectionContact className="by-sub-main-layout">
        <Container>
          <header>
            <h2 className="a11y">문의</h2>
          </header>
          <ContactUs itemData={contactData} />
        </Container>
      </SectionContact>
    </LayoutWithTitle>
  );
};

export default HospitalSupport;
